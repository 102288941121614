<template>
  <ion-page class="page bg-transparent">
    <div class="d-flex justify-content-between flex-wrap py-3">
      <div class="title">Your Quiz History</div>
    </div>

    <SearchInput
      v-model="text"
      :search-icon="true"
      :shortcut-icon="false"
      :clear-icon="false"
      :hide-shortcut-icon-on-blur="true"
      :clear-on-esc="false"
      :blur-on-esc="true"
      :select-on-focus="false"
      :shortcut-listener-enabled="true"
      placeholder="Aa"
      @input="onSearch"
    />

    <div class="d-flex py-3">
      <ion-icon class="icon mx-2 mt-2" :icon="optionsOutline" />
      <multiselect
        v-model="selectedCharacter"
        class="choose-char mx-1 w-50"
        placeholder="filter by character"
        :options="userCharacters"
        :disabled="loader"
        :multiple="false"
        :taggable="false"
        open-direction="bottom"
        :close-on-select="true"
        :loading="loader"
        :searchable="false"
        select-label=""
        deselect-label=""
        track-by="id"
        label="name"
      >
        <template #noOptions>Nothing to select</template>
      </multiselect>
    </div>

    <div v-if="!isEmpty(quizzes)">
      <div v-for="(quiz, index) of quizzes" :key="index" class="px-3 py-2 mt-2 quizzes">
        <div class="title">{{ quiz.quiz.title }}:</div>
        <div class="d-flex align-items-center mt-1">
          <img :src="displayedImage(quiz)" class="img" v-if="isCharBased(quiz)" v-character-image />
          <img :src="displayedImage(quiz)" class="img" v-else v-user-image />
          <router-link
            v-if="!isEmpty(quizzes) && !isEmpty(get(quiz.character, 'info.name'))"
            :to="{ name: 'character-profile-new', params: { slug: get(quiz, 'character.slug') } }"
            class="clickable-item-hov mx-1"
          >
            {{ get(quiz.character, 'info.name') }}
          </router-link>
          <router-link v-else :to="{ name: 'profile', params: { username: user.username } }" class="clickable-item-hov">
            <span class="mx-1">{{ user.username }}</span>
          </router-link>
        </div>

        <div class="d-flex justify-content-between mt-2" :class="{ 'flex-wrap': isMobSmallScreen }">
          <div :class="{ 'w-100': isMobSmallScreen }">
            <ion-button class="report-btn btn-design" @click="viewReport(quiz.id)">View Report</ion-button>
          </div>
          <div
            v-if="!isNull(quiz.completed_at)"
            class="d-flex align-items-center text-center"
            :class="isMobSmallScreen ? 'mx-auto' : 'ml-auto'"
          >
            Completed {{ formatSlashDate(quiz.completed_at) }}
          </div>
          <div :class="{ 'w-100': isMobSmallScreen }">
            <ion-button
              v-if="
                new Date(currentTime).getTime() >= new Date(quiz.next_day_start_date).getTime() &&
                isNull(quiz.completed_at) &&
                quiz.quiz.is_active
              "
              class="continue-btn btn-design"
              @click="openContinueModal(quiz)"
              >Continue with day {{ quiz.next_day }}</ion-button
            >
          </div>
        </div>
      </div>
      <p
        v-if="nextPageExists"
        class="d-flex justify-content-center align-items-center text-black"
        @click="requestLoadMore"
      >
        <ChLoading size="sm" v-if="isLoading" class="spinner" />
        <span v-else class="clickable-item-hov"> Load more </span>
      </p>
    </div>

    <div v-else class="no-data">No quizzes here yet!</div>
    <quiz-insights-modal
      :is-open="isOpenInsightModal"
      :char-id="charId"
      :log-id="logId"
      @close="closeInsightsModal"
      @view="closeAndOpenViewInsightModal"
    />
    <view-insight-report-modal
      :insights="quizInsights"
      :insight-index="insightIndex"
      :is-open="isOpenViewInsightModal"
      @close="closeViewInsightModal"
    />
  </ion-page>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';
import { resizeUpload } from '@/shared/utils/upload';
import Multiselect from 'vue-multiselect';
import { optionsOutline } from 'ionicons/icons';
import { getUsersQuizHistory } from '@/shared/actions/quizzes';
import QuizInsightsModal from '@/shared/modals/QuizInsightsModal.vue';
import ViewInsightReportModal from '@/shared/modals/ViewInsightReportModal.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { getUserCharacters } from '@/shared/actions/characters';
import { formatSlashDate } from '@/shared/utils/dateTime';

const text = ref('');
const selectedCharacter = ref('');
const logId = ref('');
const paging = ref({});
const quizInsights: any = ref({});
const userCharacters: any = ref([]);
const quizzes: any = ref([]);
const page = ref(1);
const isOpenInsightModal = ref(false);
const isOpenViewInsightModal = ref(false);
const router = useRouter();
const insightIndex = ref('');
const loader = ref(false);
const isLoading = ref(false);
const onSearch = () => {
  searchTextChanged();
};

const openContinueModal = (quiz: any) => {
  router.push({ name: 'quizzes', query: { quizId: quiz.quiz.id, logId: quiz.id, charId: get(quiz, 'character.id') } });
};

const fetchUsersQuizHistory = async (offset = 1) => {
  isLoading.value = true;

  const { results, ...pagingRes } = !isEmpty(selectedCharacter.value)
    ? await getUsersQuizHistory(offset, 40, { character: selectedCharacter.value.id })
    : !isEmpty(text.value)
    ? await getUsersQuizHistory(offset, 40, { search: text.value })
    : await getUsersQuizHistory(offset, 40);
  quizzes.value = offset === 1 ? results : quizzes.value.concat(results);
  page.value = offset;
  paging.value = pagingRes;
  isLoading.value = false;
};
const closeAndOpenViewInsightModal = (val: any, index: any) => {
  quizInsights.value = val;
  insightIndex.value = index;
  closeInsightsModal();
  isOpenViewInsightModal.value = true;
};

const isCharBased = (quiz: any) => {
  return get(quiz, 'quiz.is_char_based');
};
const displayedImage = (quiz: any) => {
  if (isCharBased(quiz)) {
    return get(quiz.character, 'info.cropProfilePicture');
  } else {
    return resizeUpload(user.value.profile_picture_url, '80x80');
  }
};

const currentTime = computed(() => {
  const date = new Date();
  return date;
});
const closeViewInsightModal = () => {
  isOpenViewInsightModal.value = false;
};
const viewReport = (val: any) => {
  logId.value = val;
  isOpenInsightModal.value = true;
};

const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 335;
});
const user = computed(() => {
  const { user } = authStore();
  return user.value;
});
const nextPageExists = computed(() => {
  return !!get(paging.value, 'next');
});

const closeInsightsModal = () => {
  isOpenInsightModal.value = false;
};

watch(selectedCharacter, () => {
  quizzes.value = {};
  fetchUsersQuizHistory(1);
});
const fetchUserCharacters = async () => {
  loader.value = true;
  const resp = await getUserCharacters(user.value.id, null);
  userCharacters.value = map(resp, (char: any) => ({ ...char, name: char.info.name }));
  loader.value = false;
};
const requestLoadMore = (ev: MouseEvent) => {
  if (!get(paging.value, 'next')) {
    (ev?.target as any).complete();
  } else {
    fetchUsersQuizHistory(page.value + 1);
  }
};

onMounted(() => {
  fetchUsersQuizHistory();
  fetchUserCharacters();
});
const searchTextChanged = debounce(() => {
  fetchUsersQuizHistory(1);
}, 100);
</script>

<style lang="sass" scoped>
.img
  border-radius: 15px
  width: 27px
  height: 27px
.btn-design
  --border-radius: 8px
  text-transform: none
  width: 100%
.report-btn
  --background: #00b4c5

.continue-btn
  --background: #d54fa4

.quizzes
  border: 2px solid rgb(176, 176, 176)
  border-radius: 13px
.icon
  font-size: 22px
  color: var(--ion-color-primary)
.title
  font-size: 24px
  font-weight: bold
.choose-char
  width: 200px
  @media(max-width:530px)
    width: 185px

::v-deep
  .multiselect--active .multiselect__select
    transform: rotate(0deg)

::v-deep .multiselect
  .multiselect__tags
    border-radius: 12px
    border: 3px solid var(--ion-color-primary)
    height: 34px
    min-height: 34px !important
  .multiselect__content-wrapper
    border-radius: 15px !important

  .multiselect__select
    height: 32px !important
    background: var(--ion-color-primary)
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
  .multiselect__select::before
    border-color: #fff transparent transparent transparent !important
  .multiselect__single
    line-height: 13px !important
  .multiselect__placeholder
    padding-top: 0px
  .multiselect__spinner
    height: 32px !important
    background: var(--ion-color-primary)
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
  .multiselect__spinner:before, .multiselect__spinner:after
    border-color: #ffffff transparent transparent !important
</style>
